import * as Sentry from '@sentry/vue';
import convert from 'convert-units';
import * as Throttle from 'promise-parallel-throttle';
import xhr from 'xhr';
import video from './video';
import Parse from '../parse';
import { DEFAULT_PROJECT_VIDEO } from '../../constants/video';
import { getConvertedKey } from '../../index';

// The minimum segment size is 5 MB
// https://docs.aws.amazon.com/AmazonS3/latest/API/API_UploadPart.html#API_UploadPart_RequestSyntax
const bufferSize = 5 * 1024 * 1024;

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    storageKey: {
      type: String,
      default: ''
    },
    objectInfo: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: Boolean,
    disabled: Boolean
  },
  mixins: [video],
  computed: {
    isYoutubeEnabled() {
      // Portal features
      if (this.features) {
        return this.features.youtube_video;
      }

      // Admin features
      if (this.company && this.company.features) {
        return this.company.features.youtube_video;
      }

      return false;
    }
  },
  data() {
    return {
      files: [],
      video: '',
      hasStoredVideo: false,
      videoSource: 'storage',
      youtubeUrl: '',
      storedVideoUrl: '',
      youtubeError: '',
      storedVideoError: '',
      filename: '',
      fileLength: 0,
      message: {
        mimeNotAccepted(mime) {
          throw new Error(`Not implemented: ${mime}`);
        },
        videoTooBig(max) {
          throw new Error(`Not implemented: ${max}`);
        },
        invalidVideo() {
          throw new Error('Not implemented');
        },
        videoRequired() {
          throw new Error('Not implemented');
        },
        currentVideo() {
          throw new Error('Not implemented');
        }
      },
      uploadInProgress: false,
      uploadId: null,
      progressCallback(progress) {
        throw new Error(`Not implemented : ${progress}`);
      },
      parts: []
    };
  },
  watch: {
    url: 'externalChange',
    storedVideoUrl: 'cleanupTemporaryVideo'
  },
  async created() {
    this.externalChange();
  },
  methods: {
    setMessage(message) {
      this.message = message;
    },
    setProgressCallback(progressCallback) {
      this.progressCallback = progressCallback;
    },
    getMaxUploadSize() {
      // Max part number is 10,000
      // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html
      // The minimum size for segments is 5 MB
      // https://docs.aws.amazon.com/AmazonS3/latest/API/API_UploadPart.html#API_UploadPart_RequestSyntax
      return '20000 MB';
    },
    externalChange() {
      this.video = this.url;
      const isDefaultVideo = this.isStoredVideo(this.url, DEFAULT_PROJECT_VIDEO);
      if (this.isYoutubeVideo(this.url)) {
        this.videoSource = 'youtube';
        this.youtubeUrl = this.url;
      } else if (this.isStoredVideo(this.url, this.storageKey) || isDefaultVideo) {
        this.videoSource = 'storage';
        this.storedVideoUrl = this.url;

        this.hasStoredVideo = true;
        let key = this.storageKey;
        if (isDefaultVideo) {
          key = DEFAULT_PROJECT_VIDEO;
        }

        Parse.Cloud.run('getFileLength', { key })
          .then(({ contentLength }) => {
            this.filename = this.message.currentVideo();
            this.fileLength = contentLength;
            this.storedVideoError = '';
          })
          .catch((error) => {
            if (!this.storedVideoUrl) {
              this.filename = '?';
              this.fileLength = 0;
              this.storedVideoError = error.message || this.message.invalidVideo();
            } else {
              this.filename = this.message.currentVideo();
              this.fileLength = 1060312;
            }
          });
      } else if (!this.isYoutubeEnabled
        || this.isTemporaryVideo(this.url, window.location.origin)) {
        this.videoSource = 'storage';
        this.storedVideoUrl = this.url;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        this.filename = newFile.name;
        this.fileLength = newFile.size;
        if (!this.isValidMime(newFile.type)) {
          this.storedVideoError = this.message.mimeNotAccepted(newFile.type || newFile.name);
          return prevent();
        }
        const split = (this.getMaxUploadSize()).split(' ');
        if (newFile.size > convert(split[0]).from(split[1]).to('B')) {
          this.storedVideoError = this.message.videoTooBig(this.getMaxUploadSize());
          return prevent();
        }

        this.storedVideoUrl = URL.createObjectURL(newFile.file);
        this.storedVideoError = '';
        this.internalChange();
      }
      return true;
    },
    async upload() {
      if (this.isDefaultVideo(this.storageKey)) {
        return;
      }

      const { file } = this.files[0];
      this.uploadInProgress = true;
      try {
        // cancel previous upload since only one upload id is tracked
        this.cancelUpload();

        const segments = Math.ceil(file.size / bufferSize);
        const tasks = Array(segments).fill(false);
        const { uploadId } = await Parse.Cloud.run('startFileUpload', {
          key: this.storageKey,
          contentType: file.type
        });
        this.uploadId = uploadId;

        const self = this;
        self.$emit('progress', { segments, uploaded: 0 });
        this.parts = await Throttle.all(tasks.map(Function.call, (i) => async () => {
          if (!this.uploadInProgress) {
            throw new Error(this.getUploadCancelledErrorMessage());
          }
          const preSignedUrl = await Parse.Cloud.run('signFileUrl', {
            key: this.storageKey,
            uploadId,
            partNumber: i + 1
          });

          if (!this.uploadInProgress) {
            throw new Error(this.getUploadCancelledErrorMessage());
          }
          const start = i * bufferSize;
          const end = start + bufferSize;
          const etag = await new Promise((resolve, reject) => {
            // fetch api is not supported by ie11
            xhr.put({
              url: preSignedUrl,
              body: file.slice(start, end > file.size ? file.size : end)
            }, (err, resp) => {
              if (err) {
                reject(err);
              } else {
                resolve(resp.headers.etag);
              }
            });
          });
          tasks[i] = true;
          return etag;
        }), {
          maxInProgress: 2,
          progressCallback(progress) {
            if (!self.uploadInProgress) {
              return;
            }
            if (progress.amountDone < segments) {
              self.$emit('progress', { segments, uploaded: progress.amountDone });
            }
            self.progressCallback((100 * progress.amountDone) / segments);
          }
        });
        self.$emit('progress', { segments, uploaded: segments });
      } catch (error) {
        if (error.message !== this.getUploadCancelledErrorMessage()) {
          this.storedVideoError = error.message;
          throw error;
        }
      } finally {
        this.internalChange();
        this.uploadInProgress = false;
      }
    },
    async save(key = this.storageKey) {
      if (this.hasVideoUploadIntention()) {
        if (!this.uploadId) {
          throw new Error('Invalid function call');
        }
        try {
          const { location } = await Parse.Cloud.run('completeFileUpload', {
            key: this.storageKey,
            uploadId: this.uploadId,
            parts: this.parts,
            objectInfo: this.objectInfo
          });

          this.uploadId = null;
          this.parts = [];

          if (key !== this.storageKey) {
            await Parse.Cloud.run('renameFile', {
              key,
              sourceKey: this.storageKey,
              objectInfo: this.objectInfo
            });
          }
          if (location && !this.isDefaultVideo(key)) {
            try {
              await Parse.Cloud.run('removeFile', {
                key: getConvertedKey(key),
                objectInfo: this.objectInfo
              });
              Parse.Cloud.run('startConvertVideo', { key });
            } catch (error) {
              Sentry.captureException(error);
            }
          }

          this.storedVideoUrl = await Parse.Cloud.run('getFileUrl', { key });
          // Remove signature from url
          [this.storedVideoUrl] = this.storedVideoUrl.split('?');
        } catch (error) {
          this.storedVideoError = error.message;
          throw error;
        } finally {
          this.internalChange();
          this.uploadInProgress = false;
        }

        this.clearVideoUploadIntention();
      } else if (this.hasVideoRemovalIntention()) {
        this.uploadInProgress = true;
        try {
          await Parse.Cloud.run('removeFile', {
            key,
            objectInfo: this.objectInfo
          });
          this.storedVideoUrl = '';
          this.clearVideoRemovalIntention();
        } catch (error) {
          this.storedVideoError = error.message;
          throw error;
        } finally {
          this.internalChange();
          this.uploadInProgress = false;
        }
      }
      return this.video;
    },
    async cancelUpload() {
      if (!this.uploadId) {
        return;
      }

      // must come before async calls, or the event listener won't have a chance to handle the event
      this.$emit('progress', { max: 0, val: 0 });

      this.uploadInProgress = false;
      await Parse.Cloud.run('stopFileUpload', {
        key: this.storageKey,
        uploadId: this.uploadId
      });
      this.uploadId = null;
      this.parts = [];
    },
    getUploadCancelledErrorMessage() {
      return 'Cancelled';
    },
    clearStoredVideo() {
      this.cancelUpload();
      this.filename = '';
      this.fileLength = 0;
      this.storedVideoUrl = '';
      this.storedVideoError = '';
      this.internalChange();
      this.clearVideoUploadIntention();
    },
    hasVideoUploadIntention() {
      return this.videoSource === 'storage'
        && this.files.length > 0
        && !this.storedVideoError
        && !this.isDefaultVideo(this.storageKey);
    },
    clearVideoUploadIntention() {
      // TODO files data entry is not reactive to the host of this mixin.
      this.files = [];
      this.$emit('clearUploadIntention', true);
    },
    hasVideoRemovalIntention() {
      return (this.videoSource !== 'storage' || !this.storedVideoUrl)
        && this.hasStoredVideo
        && !this.isDefaultVideo(this.storageKey);
    },
    clearVideoRemovalIntention() {
      this.hasStoredVideo = false;
    },
    internalChange() {
      if (this.videoSource === 'youtube') {
        this.switchToYoutube();
      } else if (this.videoSource === 'storage') {
        this.switchToStoredVideo();
      }
    },
    switchToYoutube() {
      this.youtubeError = '';
      this.video = '';
      if (this.youtubeUrl && !this.isYoutubeVideo(this.youtubeUrl)) {
        this.youtubeError = this.message.invalidVideo();
      } else if (!this.youtubeUrl && this.required) {
        this.youtubeError = this.message.videoRequired();
      } else {
        this.video = this.youtubeUrl;
      }
      this.$emit('change', this.video);
      this.$emit('validate', this.youtubeError);
    },
    switchToStoredVideo() {
      if (!this.storedVideoError && this.required && !this.storedVideoUrl) {
        this.storedVideoError = this.message.videoRequired();
      }
      if (!this.storedVideoError) {
        this.video = this.storedVideoUrl;
      } else {
        this.video = '';
      }
      this.$emit('change', this.video);
      this.$emit('validate', this.storedVideoError);
    },
    validate() {
      // See checkFields(), validateMixin.js
      // Can we return the validation result from here somehow?
      this.internalChange();
    },
    getMegaBytes(bytes) {
      return new Intl.NumberFormat(navigator.language, { maximumSignificantDigits: 3 })
        .format(convert(bytes).from('B').to('MB'));
    },
    cleanupTemporaryVideo(newUrl, oldUrl) {
      if (this.isTemporaryVideo(oldUrl, window.location.origin)) {
        // As long as the mapping exist the Blob can’t be garbage collected,
        // so some care must be taken to revoke the URL as soon as the reference
        // is no longer needed. - https://www.w3.org/TR/FileAPI/#url
        URL.revokeObjectURL(oldUrl);
      }
    }
  }
};
