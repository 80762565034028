<template>
  <div class="video-upload mb-4">
    <v-radio-group
      v-if="youtube_video_feature"
      v-model="videoSource"
      v-ruid="'video-upload'"
      row
      @change="internalChange"
    >
      <v-radio
        label="Youtube Link"
        value="youtube"
        name="youtube"
        :disabled="disabled || uploadInProgress"
      />
      <v-radio
        label="Video File"
        value="storage"
        name="file"
        :disabled="disabled || uploadInProgress"
      />
    </v-radio-group>
    <v-text-field
      v-if="videoSource === 'youtube'"
      v-model="youtubeUrl"
      v-ruid="'youtubeVideo'"
      :label="label"
      :placeholder="placeholder"
      clearable
      :error-messages="youtubeError ? [youtubeError] : []"
      @input="internalChange"
    />
    <div
      v-if="videoSource === 'storage'"
    >
      <div
        v-if="(filename || fileLength) && url"
        class="d-flex video-info-bubble"
        :class="{ 'video-error': storedVideoError }"
      >
        <div class="video-info-content">
          <p class="video-filename">
            {{ filename }}
          </p>
          <p class="video-file-length">
            {{ getMegaBytes(fileLength) }} MB
          </p>
          <p
            v-if="uploadInProgress"
            style="width: 100%;"
          >
            <label>Uploading...</label>
            <progress-bar
              size="small"
              :bar-border-radius="5"
              :val="videoProgress"
            />
          </p>
        </div>
        <div class="clear-video-button-container">
          <v-icon
            class="clear-video-button"
            :style="{ visibility: disabled ? 'hidden' : 'visible' }"
            @click="clearStoredVideo"
          >
            clear
          </v-icon>
        </div>
      </div>
      <file-upload
        v-if="!storedVideoUrl"
        v-model="files"
        v-ruid="'videoFileInput'"
        :multiple="false"
        :input-id="storageKey"
        :disabled="disabled"
        accept=".mp4,.mov"
        post-action="/parse/files/"
        @input-filter="inputFilter"
        @input="upload()"
      >
        <app-button
          :disabled="disabled"
          secondary
        >
          Upload video
        </app-button>
      </file-upload>
      <slot />
      <p
        v-if="storedVideoError"
        class="error-msg mt-2"
      >
        {{ storedVideoError }}
      </p>
    </div>
  </div>
</template>

<script>
import videoUpload from '@kickbox/common-util/src/mixins/videoUpload';
import FileUpload from 'vue-upload-component';
import ProgressBar from 'vue-simple-progress';

export default {
  name: 'VideoUpload',
  components: {
    FileUpload,
    ProgressBar,
  },
  mixins: [videoUpload],
  data() {
    return {
      features: this.$store.getters.company.features,
      message: {
        mimeNotAccepted(mime) {
          return `File format is not accepted, ${mime}.`;
        },
        videoTooBig(max) {
          return `Your video file can not be uploaded because it is too large. The maximum size you can upload is ${max}.`;
        },
        invalidVideo() {
          return 'The video is not valid';
        },
        videoRequired() {
          return 'Video is required.';
        },
        currentVideo() {
          return 'Current uploaded video';
        },
      },
      videoProgress: 0,
      progressCallback(progress) {
        this.videoProgress = progress;
      },
      youtube_video_feature: false,
    };
  },
  created() {
    this.youtube_video_feature = this.$store.getters.company.features.youtube_video;
    if (!this.youtube_video_feature) {
      this.videoSource = 'storage';
    }
  },
};
</script>

<style lang="scss">
  .file-uploads {
    display: inline !important;
  }
  .file-uploads.file-uploads-html5 {
    label {
      cursor: pointer;
      height: 50px !important;
      top: -90% !important;
      bottom: 0 !important;
    }
  }
</style>

<style scoped lang="scss">
  img {
    height: 48px;
    cursor: pointer;
  }

  .error-msg {
    color: #ff4a4a;
    font-size: 12px;
  }

  .btn__content {
    line-height: 36px;
  }

  ::v-deep .input-group__details {
    min-height: 0;
  }

  .input-group--text-field {
    margin-top: 1em;
  }

  .video-info-bubble {
    background-color: #eef6ff;
    margin: 0.5rem 1rem 0 0;
  }

  .video-info-bubble {
    border-radius: 6px;
    padding: 0 1em;
    position: relative;
  }

  .clear-video-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .clear-video-button {
    cursor: pointer;
  }

  .video-info-content {
    width: 100%;
    padding-top: 1em;
  }

  .video-filename {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
  }

  .video-file-length {
    font-size: 13px;
    font-weight: 500;
    color: #939ea9;
  }

  .video-error {
    background-color: #fdf3f3;
    border-color: #ff4a4a;
  }
</style>
